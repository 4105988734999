<template>
  <div class="share-buttons">
    <a href="https://www.facebook.com/sharer/sharer.php" target="_blank"> <font-awesome-icon :icon="['fab', 'facebook-square']" fixed-width /> </a>
    <a class="twitter" :href="`https://twitter.com/intent/tweet?text=${text}!&url=https://${$domain}${this.$route.path}`" target="_blank"> <font-awesome-icon :icon="['fab', 'twitter-square']" fixed-width /> </a>
  </div>
</template>

<script>

export default {
  props: {
    text: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss">
  .share-buttons {
    margin-left: -8px;
    font-size: 2.7rem;
    cursor: pointer;

    @include tablet() {
      margin-bottom: -2rem;
    }

    a {
      color: #2a3c4a;
      transition: .2s;

      &.twitter {
        margin-left: -20px;
      }

      &:hover {
        color: #6bd100;
      }
    }
  }

</style>
