<template>
  <div>
    <main-hero
      title="Kilpailuta autorahoitus nopeasti ja maksutta"
      subtitle="Vertaile autorahoitusvaihtoehtoja ja valitse sopivin"
      side-title="Täytä rahoitushakemus tästä!"
      :side-boxes="sideBoxes"
      survey-title="Asiakasarviot"
      :breadcrumb="{'Autolaina': '/autolaina', 'Autorahoitus': ''}"
    />
    <section class="hero has-background-rlgray">
      <div class="hero-body">
        <div class="container">
          <h2 class="title has-text-white is-size-4 has-text-centered">
            Kilpailuta autorahoitus nyt.
          </h2>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <lazy-hydrate when-visible>
          <loan-steps />
        </lazy-hydrate>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="content">
          <h2>
            Miksi hakea autorahoitus netistä?
          </h2>
          <p>
            Kymmenen toimintavuotemme aikana olemme kilpailuttaneet tuhansien autonostajien lainoja ja auttaneet löytämään edullista rahoitusta auton ostoon. Saat kauttamme autorahoitustarjoukset 70 000 euroon asti, eli lainaa järjestyy monipuolisesti minkä hintaiseen ja tasoiseen ajoneuvoon tahansa.
          </p>
          <p>
            Monet autoliikkeet ja pankit tarjoavat myös autorahoitusta, joten saatat miettiä miksi sinun kannattaisi hoitaa auton rahoitus Rahalaitoksen kautta.
          </p>
          <p>
            Autorahoituksen kilpailutus avullamme kannattaa muun muassa seuraavien syiden takia:
          </p>
          <h3>
            1. Se on maksutonta, etkä sitoudu mihinkään
          </h3>
          <p>
            Saat ensimmäiset autorahoitustarjoukset jo muutamassa minuutissa hakemuksen jättämisestä. Voit vertailla tarjouksia Oma Rahalaitos -palvelussamme ja päättää sen jälkeen, otatko rahoituksen vai et.
          </p>
          <p>
            Mikäli yksikään saamistasi tarjouksista ei syystä tai toisesta tunnu riittävän hyvältä, jätät vain lainan ottamatta, eikä tästä koidu sinulle mitään kustannuksia.
          </p>
          <h3>
            2. Autolainan hakeminen verkosta on nopeaa
          </h3>
          <p>
            Lainan hakeminen perinteisestä pankista on hidasta. Vertailua suorittaaksesi sinun täytyisi tehdä lainahakemus jokaiseen pankkiin erikseen. Rahalaitoksen avulla täytät vain yhden hakemuksen, joten saat lyötyä monta kärpästä yhdellä iskulla.
          </p>
          <p>
            Laina tilille maksaminen onnistuu yhteistyökumppaneiltamme ripeästi. Nopeimmillaan voit saada autorahoituksen käyttöösi vielä samana päivänä sopimuksen allekirjoituksesta.
          </p>
          <h3>
            3. Autorahoitus ilman vakuuksia tai takaajia
          </h3>
          <p>
            Autorahoitusta hakiessasi joudut usein asettamaan ostamasi auton tai muuta omaisuuttasi lainan takaisinmaksun vakuudeksi. Rahalaitoksen kautta autorahoituksen hakeminen onnistuu täysin ilman vakuuksia.
          </p>
          <p>
            Tämä helpottaa ja nopeuttaa myös auton myymistä, kun et ole joutunut laittamaan sitä autolainasi pantiksi. Kannattaa myös huomioida, että liian huonossa kunnossa olevaa tai vanhaa autoa ei välttämättä edes kelpuuteta autolainan vakuudeksi.
          </p>
          <p>
            Lisäksi vakuudeton laina on yleensä vakuudellista helpompi järjestää, mikäli olet ostamassa autoa yksityiseltä myyjältä autoliikkeen sijaan. Autorahoitus yksityiseltä myyjältä onnistuu toki perinteisenkin pankin kautta, mutta tällöin saatat joutua asettamaan autosi jälleen vakuudeksi.
          </p>
          <h3>
            4. Autorahoituksen kilpailuttaminen säästää selvää rahaa
          </h3>
          <p>
            Halvin autorahoitus löytyy aina helpommin, kun pystyt samalla kertaa tutkailemaan useita eri vaihtoehtoja. Näiden joukosta voit valita autolainan, jossa todellinen vuosikorko on kaikista pienin tai jonka maksuehdot miellyttävät eniten.
          </p>
          <p>
            Jos kyse on vähänkään isommasta autolainasta, erot korkokustannuksissa voivat helposti olla tuhansia euroja. Edullisen autorahoituksen löytämise vertailu siis kannattaa. Oma Rahalaitos -palvelussa pystyt vertailemaan autorahoitusten korkoja ja kustannuksia hakemuksen jätettyäsi kätevästi yhdellä sivulla.
          </p>
          <h3>
            5. Lainaa maksat takaisin sinulle sopivalla aikataululla
          </h3>
          <p>
            Takaisinmaksuaikaa autorahoitukseen voit saada 1-15 vuotta. Säästät kustannuksissa sitä enemmän, mitä nopeammin maksat lainan pois. Eripituisten laina-aikojen kustannuksia voit vertailla suuntaa-antavasti lainalomakkeestamme löytyvän lainalaskurin avulla.
          </p>
          <p>
            Valitse lainalaskurissamme tarvitsemasi autorahoituksen määrä sekä takaisinmaksuaika ja laskuri antaa sinulle välittömästi tiedon siitä, mikä takaisinmaksueräsi summa voisi olla.
          </p>
          <h2>
            Voit hakea lainaa autorahoitukseen aina 70 000 euroon asti
          </h2>
          <p>
            Uuden auton ostaminen on aina iso taloudellinen päätös, josta ei usein selvitä ilman rahoitusta. Lainaa auton ostamiseen voit hakea perinteisestä kivijalkapankista, autoliikkeen kautta tai kätevästi suoraan verkosta.
          </p>
          <p>
            Rahalaitos on täysin suomalainen lainanvälityspalvelu, joka tekee yhteistyötä lukuisien luotonmyöntäjien, pankkien ja rahoituslaitosten kanssa. Saat palvelumme avulla haettua yhdellä hakemuksella autolainatarjoukset kaikilta yhteistyökumppaneiltamme. Kilpailutamme autorahoituksen aina ilmaiseksi, eikä se sido sinua mihinkään.
          </p>
          <h2>
            Auton rahoitus lainan avulla kannattaa kilpailuttaa
          </h2>
          <p>
            Rahalaitoksen kumppanit tarjoavat lainaa autorahoitusta varsin nopeasti ja joustavasti. Rahalaitos ei itse lainaa rahaa, vaan välittää autorahoitusta tarvitsevien hakemukset useille luotettaville rahoituskumppaneille ja pyytää näiltä tarjoukset. Autorahoituksen hakija voi vertailla saamiaan tarjouksia palvelussamme ja valita halutessaan niistä itselleen sopivimman.
          </p>
          <p>
            Autorahoituksen kilpailuttaminen Rahalaitoksen palvelussa onnistuu ilman reaalivakuuksia, eli autoa ei tarvitse asettaa lainan pantiksi, ja verkossa luoton hakeminen on helppoa ja nopeaa. Voit kilpailuttaa autorahoituksen Rahalaitoksella aina ilman vakuuksia. Kun täytät hakemuksen, saat nopeasti tarjoukset kumppaneiltamme ja voit tehdä vertailun mikä saamistasi lainatarjouksista on sinulle paras.
          </p>
          <p>
            Autorahoituksen vaihtoehtojen vertailu on hyvä keino säästää kuluissa. Kilpailuttamalla lainan ja vertaamalla tarjouksia voi säästää jopa tuhansia euroja. Palvelumme avulla säästät myös aikaa, kun etsimme puolestasi sinulle edullisen lainan. Rahalaitos on puolueeton toimija ja meille tärkeintä on löytää lainanhakijoillemme paras mahdollinen laina.
          </p>
          <p>
            Lainahakemuksen täyttäminen ja autorahoitusten kilpailuttaminen eivät sido ottamaan lainaa ja se on täysin ilmaista.
          </p>
          <h2>
            Autorahoitus nopeasti ja ilman vakuuksia onnistuu netissä
          </h2>
          <p>
            Nopeasti saatavilla oleva autorahoitus voi olla merkittävä tekijä autonhankinnan kannalta. Joskus esimerkiksi hyvien kauppojen kiinnilyöminen vaatii nopeita liikkeitä, ja nopea autorahoitus voi olla avain liiketoiminnan menestykseen.
          </p>
          <p>
            Rahalaitoksen kautta voit hakea lainaa suoraan netin kautta, ilman paperisten lomakkeiden täyttöä ja pankkikonttoreissa jonottamista. Moni kumppaninamme toimiva lainantarjoaja antaa rahoituspäätöksen suoraan automatisoidun hakuprosessin kautta, jolloin lainapäätöksen voi saada hetkessä. Hyväksytty laina voidaan usein maksaa hakijan tilille jo samana päivänä.
          </p>
          <h2>
            Autorahoituksen ehdot
          </h2>
          <p>
            Rahalaitos ei toimi lainanmyöntäjänä, vaan ainoastaan tarjousten välittäjänä. Muutamien ehtojen tulee kuitenkin pääsääntöisesti aina täyttyä, jotta sinulle voidaan myöntää autolainaa kauttamme.
          </p>
          <p>
            Lainanhakijoille asetetut ehdot ovat seuraavat:
          </p>
          <ul>
            <li>Vähintään 20 vuoden ikää</li>
            <li>Kuukausitulot yli 600 €</li>
            <li>Ei julkisia maksuhäiriömerkintöjä</li>
          </ul>
          <p>
            Eri lainantarjoajat suhtautuvat kelpoisuusehtoihin hieman eri tavoin. Sen takia kannattaa kilpailuttaa laina Rahalaitoksella ja näin rahoitushakemus toimitetaan kerralla usealle lainantarjoajalle. Jos et saa lainaa yhdestä yhtiöstä, voit saada positiivisen rahoituspäätöksen toiselta toimijalta.
          </p>
          <h2>
            Tee autorahoitusvertailu nyt!
          </h2>
          <p>
            Mikäli täytit yllä luetellut kriteerit, täytä lainahakemus nyt ja hae itsellesi edullinen autolaina verkosta vielä tänään. Saat muutamassa minuutissa päätöksen siitä, voidaanko sinulle myöntää autolainaa. Ensimmäiset lainatarjoukset saat vertailtavaksesi nopeasti.
          </p>
          <p>
            Lainan hakeminen kauttamme on ilmaista, nopeaa ja riskitöntä. Halvin <nuxt-link to="/autolaina">autolaina</nuxt-link> löytyy aina vasta kilpailutuksen tuloksena, joten kannattaa toimia nyt!
          </p>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <lazy-hydrate when-visible>
          <subpages />
        </lazy-hydrate>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <lazy-hydrate when-visible>
          <banks />
        </lazy-hydrate>
      </div>
    </section>
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'

export default {
  name: 'AutoLaina',
  components: {
    LazyHydrate,
    banks: () => import('~/components/layout/banks'),
    loanSteps: () => import('~/components/layout/loanSteps'),
    mainHero: () => import('~/components/layout/mainHero'),
    subpages: () => import('~/components/layout/subpages')
  },
  data () {
    return {
      sideBoxes: [
        {
          icon: () => import('~/assets/img/icons/banks.svg?inline'),
          title: 'Kilpailuta autorahoitus',
          subtitle: 'kattavasti'
        },
        {
          icon: () => import('~/assets/img/icons/time.svg?inline'),
          title: 'Nopea ja helppo',
          subtitle: 'kilpailutus'
        },
        {
          icon: () => import('~/assets/img/icons/money.svg?inline'),
          title: 'Autorahoitus jopa',
          subtitle: '70 000 €'
        }
      ]
    }
  },
  async fetch ( { store } ) {
    await store.dispatch( 'subpage/setPages', [
      {
        title: 'Lainaa ilman takaajia',
        subtitle: 'Haaveista totta nopeasti ja helposti',
        link: '/lainaa-ilman-takaajia'
      },
      {
        title: 'Lainaa 30 000 euroa',
        subtitle: 'Suuriin ja pieniin unelmiin',
        link: '/lainaa-30000-euroa'
      },
      {
        title: 'Yhdistä lainasi',
        subtitle: 'Selkiytä talouttasi ja säästä kuluissa',
        link: '/yhdista-lainat'
      }
    ] )
  },
  head () {
    return {
      title: 'Autorahoitus - Kilpailuta autorahoitus | Rahalaitos',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: `Rahalaitoksen kautta voit saada jopa 70 000 € lainaa ilman vakuuksia
            auton ostoa varten. Täytä hakemus ilmaiseksi jo tänään!`
        }
      ]
    }
  }
}
</script>
