<template>
  <div class="columns is-multiline decision-details-toggle">
    <div class="column is-12 has-text-centered is-flex-mobile" :class="{ 'is-hidden-mobile': chosen }">
      <button class="button is-primary is-rounded" :class="{ 'is-outlined': !chosen }" @click.prevent.stop="emitEvent">{{ buttonText }}</button>
    </div>
    <div v-if="chosen && !eSignClicked" class="column has-text-centered decision-details-toggle-remove">
      <span class="is-size-7" @click.prevent.stop="emitEvent">Poista valinta</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DecisionToggle',
  props: {
    chosen: {
      required: true,
      type: Boolean
    },
    eSignClicked: {
      required: true,
      type: Boolean
    }
  },
  computed: {
    buttonText () {
      if ( this.eSignClicked ) {
        return 'Allekirjoita tästä'
      }
      else if ( this.chosen ) {
        return 'Valittu'
      }
      else {
        return 'Valitse'
      }
    }
  },
  methods: {
    emitEvent () {
      if ( this.eSignClicked ) {
        this.$emit( 'signOffer' )
      }
      else {
        this.$emit( 'toggleOffer' )
      }
    }
  }
}
</script>

<style lang="scss">
.decision-details-toggle {

  @include mobile {
    button.button.is-primary {
      background-color: transparent;
      border-color: #6bd100;
      color: #6bd100;
      flex-grow: 1;
      margin: 0.75rem 0.25rem 0.5rem;
      &:hover,
      &:focus {
        background-color: #6bd100;
        border-color: #6bd100;
        color: #fff;
      }
    }
  }
  @include tablet {
    .column:first-child {
      padding-top: 1.5rem;
      padding-bottom: 0;
    }

    .column:nth-child(2):hover {
      text-decoration: underline;
    }

    .column:last-child {
      padding-top: 0;
      top: 20px;
    }
  }
}
</style>
