<template>
  <div>
    <main-hero
      title="Kilpailuta Autolaina maksutta"
      subtitle="Yhdellä hakemuksella useita lainatarjouksia."
      side-title="Kilpailuta vakuudettomat autolainat!"
      :side-boxes="sideBoxes"
      survey-title="Asiakasarviot"
      :breadcrumb="{'Autolaina': ''}"
    />
    <section class="hero has-background-rlgray">
      <div class="hero-body">
        <div class="container">
          <h2 class="title has-text-white is-size-4 has-text-centered">
            Autolaina kannattaa kilpailuttaa.
          </h2>
          <div id="giosg-bot"></div>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <lazy-hydrate when-visible>
          <loan-steps />
        </lazy-hydrate>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="content">
          <h3>
            Voit hakea lainaa auton ostoon aina 70 000 euroon asti
          </h3>
          <p>
            Auton ostaminen on aina suomalaiselle iso taloudellinen päätös, josta ei usein selvitä ilman lainarahaa. Lainaa auton ostamiseen voit hakea perinteisestä kivijalkapankista, autoliikkeen kautta tai kätevästi suoraan verkosta.
          </p>
          <p>
            Rahalaitos on täysin suomalainen lainanvälityspalvelu, joka tekee yhteistyötä yli 25 luotonmyöntäjän, pankin ja rahoituslaitoksen kanssa. Saat palvelumme avulla haettua yhdellä hakemuksella autolainatarjoukset kaikilta yhteistyökumppaneiltamme. Kilpailutamme lainasi aina ilmaiseksi, eikä se sido sinua mihinkään.
          </p>
          <h2>
            5 hyvää syytä kilpailuttaa autolainat meidän avulla
          </h2>
          <p>
            Toimintavuosiemme aikana kymmenet tuhannet suomalaiset ovat kääntyneet puoleemme autolainoja vertaillessaan. Voit kilpailuttaa kauttamme 500-70 000 euron suuruiset autolainat eli lainaa järjestyy monipuolisesti minkä hintaiseen ja tasoiseen ajoneuvoon tahansa.
          </p>
          <p>
            Autolainan hakeminen avullamme kannattaa muun muassa seuraavien syiden takia:
          </p>
          <h3>
            1. Se on maksutonta, etkä sitoudu mihinkään
          </h3>
          <p>
            Saat ensimmäiset autolainatarjoukset usein jo muutamassa minuutissa hakemuksen jättämisestä. Voit vertailla lainoja Oma Rahalaitos -palvelussamme ja päättää sen jälkeen, otatko autolainaa vai et. Jos mikään saamistasi tarjouksista ei syystä tai toisesta tunnu riittävän hyvältä, jätät vain lainan ottamatta, eikä tästä koidu sinulle mitään kustannuksia.
          </p>
          <h3>
            2. Autolainan hakeminen verkosta on nopeaa
          </h3>
          <p>
            Lainan hakeminen perinteisestä pankista on hidasta. Vertailua suorittaaksesi sinun täytyisi tehdä lainahakemus jokaiseen pankkiin erikseen. Rahalaitoksen avulla täytät vain yhden hakemuksen, joten saat lyötyä monta kärpästä yhdellä iskulla.
          </p>
          <p>
            Laina tilille maksaminen onnistuu yhteistyökumppaneiltamme ripeästi. Nopeimmillaan voit saada autolainan käyttöösi vielä samana päivänä.
          </p>
          <h3>
            3. Voit saada autolainaa ilman vakuuksia tai takaajia
          </h3>
          <p>
            Autolainaa hakiessasi joudut usein asettamaan ostamasi auton tai muuta omaisuuttasi lainan takaisinmaksun vakuudeksi. Rahalaitoksen kautta autolainan hakeminen onnistuu täysin ilman vakuuksia. Tämä helpottaa ja nopeuttaa myös auton myymistä, kun et ole joutunut laittamaan sitä autolainasi pantiksi. Kannattaa myös huomioida, että liian huonossa kunnossa olevaa tai vanhaa autoa ei välttämättä edes kelpuuteta autolainan vakuudeksi.
          </p>
          <p>
            Lisäksi vakuudeton laina on yleensä vakuudellista helpompi järjestää, mikäli olet ostamassa autoa yksityiseltä myyjältä autoliikkeen sijaan.
          </p>
          <h3>
            4. Autolainan kilpailuttaminen säästää sinulta selvää rahaa
          </h3>
          <p>
            Halvin autolaina löytyy aina helpommin, kun pystyt samalla kertaa tutkailemaan useita kymmeniä vaihtoehtoja. Näiden joukosta voit valita autolainan, jossa todellinen vuosikorko on kaikista pienin tai jonka maksuehdot miellyttävät eniten.
          </p>
          <p>
            Jos kyse on vähänkään isommasta autolainasta, erot korkokustannuksissa voivat helposti olla tuhansia euroja. Autolainojen vertailu siis kannattaa. Oma Rahalaitos -palvelussa pystyt tutkailemaan lainojen kustannuksia hakemuksen jätettyäsi kätevästi yhdellä sivulla.
          </p>
          <h3>
            5. Lainaa maksat takaisin sinulle sopivalla aikataululla
          </h3>
          <p>
            Takaisinmaksuaikaa autolainallesi voit saada 1-15 vuotta. Säästät kustannuksissa sitä enemmän, mitä nopeammin maksat lainan pois. Eripituisten laina-aikojen kustannuksia voit vertailla suuntaa-antavasti lainalomakkeestamme löytyvän laskurin avulla.
          </p>
          <h2>
            Lainaa auton ostoon voit hakea näiden ehtojen täyttyessä
          </h2>
          <p>
            Rahalaitos ei toimi lainanmyöntäjänä, vaan ainoastaan tarjousten välittäjänä. Muutamien ehtojen tulee kuitenkin pääsääntöisesti aina täyttyä, jotta sinulle voidaan myöntää autolainaa. Lainanhakijoille asetetut ehdot ovat seuraavat:
          </p>
          <ul>
            <li>Vähintään 20 vuoden ikä.</li>
            <li>Kuukausitulot yli 600 euroa.</li>
            <li>Ei julkisia maksuhäiriömerkintöjä.</li>
          </ul>
          <h3>
            Tee autolainojen vertailu nyt!
          </h3>
          <p>
            Mikäli täytit yllä luetellut kriteerit, täytä lainahakemus nyt ja hae itsellesi edullinen autolaina verkosta vielä tänään. Saat muutamassa minuutissa päätöksen siitä, voidaanko sinulle myöntää autolainaa. Ensimmäiset lainatarjoukset saat vertailtavaksesi nopeasti.
          </p>
          <p>
            Lainan hakeminen kauttamme on ilmaista, turvallista ja riskitöntä. Halvin autolaina löytyy aina vasta kilpailutuksen tuloksena, joten mitä enää odotat?
          </p>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <lazy-hydrate when-visible>
          <faq />
        </lazy-hydrate>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <lazy-hydrate when-visible>
          <subpages />
        </lazy-hydrate>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <lazy-hydrate when-visible>
          <banks />
        </lazy-hydrate>
      </div>
    </section>
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'

export default {
  name: 'AutoLaina',
  components: {
    LazyHydrate,
    banks: () => import('~/components/layout/banks'),
    faq: () => import('~/components/layout/pages/autolainaFaq'),
    loanSteps: () => import('~/components/layout/loanSteps'),
    mainHero: () => import('~/components/layout/mainHero'),
    subpages: () => import('~/components/layout/subpages')
  },
  data () {
    return {
      sideBoxes: [
        {
          icon: () => import('~/assets/img/icons/banks.svg?inline'),
          title: 'Autolainaa jopa',
          subtitle: '25 pankilta'
        },
        {
          icon: () => import('~/assets/img/icons/time.svg?inline'),
          title: 'Helposti ja',
          subtitle: 'nopeasti'
        },
        {
          icon: () => import( '~/assets/img/icons/money.svg?inline' ),
          title: 'Autolainaa jopa',
          subtitle: '70 000 €'
        }
      ]
    }
  },
  async fetch ( { store } ) {
    await store.dispatch( 'subpage/setPages', [
      {
        title: 'Lainaa ilman takaajia',
        subtitle: 'Haaveista totta nopeasti ja helposti',
        link: '/lainaa-ilman-takaajia'
      },
      {
        title: 'Lainaa 30 000 euroa',
        subtitle: 'Suuriin ja pieniin unelmiin',
        link: '/lainaa-30000-euroa'
      },
      {
        title: 'Yhdistä lainasi',
        subtitle: 'Selkiytä talouttasi ja säästä kuluissa',
        link: '/yhdista-lainat'
      }
    ] )
  },
  head () {
    return {
      title: 'Autolaina - Kilpailuta autolaina | Rahalaitos',
      meta: [
        { hid: 'description', name: 'description', content: 'Rahalaitoksen kautta voit saada jopa 70 000 € lainaa ilman vakuuksia auton ostoa varten. Täytä hakemus ilmaiseksi jo tänään!' }
      ],
      link: [
        { rel: 'canonical', href: 'https://www.rahalaitos.fi/autolaina' }
      ]
    }
  }
}
</script>
