
<template>
  <div v-tooltip="{ content: tooltip }" class="field has-addons is-switch">
    <div class="control is-expanded">
      <label
        v-if="label"
        :for="name"
        :class="{ 'is-as-label': isStatic }"
        class="label"
      >
        {{ label }}
      </label>
      <div v-if="!isStatic" :name="name" class="buttons has-addons">
        <button
          :class="{ 'is-primary' : switchValue === false }"
          type="button"
          class="button"
          @click.prevent="changeValue(false)"
        >
          {{ choices[0] }}
        </button>
        <button
          :class="{ 'is-primary' : switchValue }"
          type="button"
          class="button"
          @click.prevent="changeValue(true)"
        >
          {{ choices[1] }}
        </button>
      </div>
      <input
        v-else
        :value="switchValue ? choices[1] : choices[0]"
        class="input is-static has-padding"
        readonly
      >
    </div>
    <transition name="slideDown">
      <p v-if="error" class="help is-danger">{{ error }}</p>
    </transition>
  </div>
</template>

<script>
export default {
  $_veeValidate: {
    value () {
      return this.value
    },
    name () {
      return this.name
    },
    alias () {
      return this.label || this.name
    },
    events: 'input'
  },
  props: {
    bool: {
      default: true,
      type: Boolean
    },
    choices: {
      default: () => ['Ei', 'Kyllä'],
      type: Array,
      validator ( value ) {
        return value.length === 2
      }
    },
    error: {
      default: '',
      type: String
    },
    isStatic: {
      default: false,
      type: Boolean
    },
    label: {
      default: '',
      type: String
    },
    name: {
      required: true,
      type: String
    },
    tooltip: {
      default: '',
      type: String
    },
    value: {
      default: null,
      type: [Boolean, String, Number]
    }
  },
  data () {
    return {
      switchValue: this.getInternalValue()
    }
  },
  watch: {
    value () {
      this.switchValue = this.getInternalValue()
    }
  },
  methods: {
    changeValue ( value ) {
      this.switchValue = value
      this.$emit( 'input', this.getExternalValue( value ) )
    },
    getInternalValue ( value = this.value ) {
      // When value is not set dont set the value
      if ( value === null ) {
        return null
      }
      else if ( typeof value === 'boolean' ) {
        return value
      }
      else if ( typeof value === 'string' ) {
        return ( value === '1' || value === 'true' )
      }
      return Boolean( value )
    },
    getExternalValue ( value = this.switchValue ) {
      if ( value === null ) {
        return null
      }
      if (this.bool) {
        return value
      }
      return value ? '1' : '0'
    }
  }
}
</script>

<style lang="scss">
  .is-switch {
    flex-direction: column;
    label.label {
      margin-bottom: 0;
    }
    .buttons {
      flex-wrap: nowrap;
      .button {
        width: 50%;
      }
    }
  }
</style>
