








































































import Vue from 'vue'
import faq from '~/components/layout/faq.vue'
import sliders from '~/components/subpage/sliders.vue'

/* eslint-disable vue/one-component-per-file */
const questions = Vue.extend( {
  name: 'IdentQuestions',
  extends: faq,
  data () {
    return {
      questions: [
        {
          title: 'Mikä on identiteettivakuutus?',
          content: `Identiteettivakuutus on sekä ennaltaehkäisevä että menetyksiä turvaava vakuutus
            identiteettivarkautta vastaan. Vakuutukseen kuuluu henkilötunnuksen ja sähköpostiosoitteiden
            monitorointipalvelu, joka seuraa tietojesi käyttöä verkossa. Lisäksi saat vahingon sattuessa tukea
            aiheettomien laskujen ja luottojen kiistämiseen ja luottokiellon asettamiseen.
            Identiteettivarkaudesta johtuvia kustannuksia vakuutus korvaa jopa 20 000 euron edestä yhtä
            vakuutuskautta (12 kk) kohden.`
        },
        {
          title: 'Mitä tarkoittaa monitorointipalvelu?',
          content: `Monitorointipalvelu valvoo tietojasi sekä julkisessa internet-verkossa että etenkin rikollisten
            suosimissa anonyymeissä vertaisverkoissa. Salaisia vertaisverkkoja eli niin sanottuja pimeitä
            verkkoja käytetään usein varastettujen identiteettitietojen kauppapaikkoina.
            Identiteettivakuutukseen kuuluva monitorointipalvelu toimii automaattisesti skannaamalla
            antamiasi henkilötietoja ja sähköpostiosoitteita suurista tietomassoista automatisoitujen robottien
            ja palkattujen henkilöiden avulla. Hälytystasoja on kolme: matala, keskitasoinen ja korkea.
            Jos havaitsemme henkilötietojesi olevan vaarassa joutua vääriin käsiin, olemme sinuun välittömästi
            yhteydessä ja neuvomme, miten toimia. Voit myös itse kirjautua mySafetyn monitorointipalvelun
            hallintaportaaliin, josta näet suoraan, minkälaisia toimenpiteitä suositellaan kunkin hälytyksen
            osalta.`
        },
        {
          title: 'Kannattaako identiteettivakuutuksen ottaminen?',
          content: `Tietomurrot, joissa on varastettu suomalaisten henkilötietoja ovat olleet viime aikoina enenevissä
            määrin julkisuudessa ja erilaiset Identiteettivarkaudet ja niiden yritykset ovat lisääntyneet
            Suomessa ja maailmalla merkittävästi viime vuosina. Vuonna 2020 julkaistun tutkimuksen mukaan,
            edellisen 12 kuukauden aikana yli puoli miljoonaa suomalaista joutui identiteettivarkausyrityksen
            kohteeksi ja 45 000 suomalaista identiteettivarkauden uhriksi. Vuonna 2020 julkisuuteen on tullut
            vielä suurempi määrä tietomurrossa vääriin käsiin joutuneita arkaluontoisia henkilötietoja.
            Identiteettivarkaus voi tapahtua milloin ja kenelle tahansa. Tällaisessa tilanteessa ihminen tarvitsee
            tukea ja selvät toimintaohjeet aiheutuneiden vahinkojen minimoimiseksi. Tällaisessa vaikeassa
            tilanteessa identiteettivakuutus on hyvä apu ja turva mielenrauhan palauttamiseen.`
        },
        {
          title: 'Miten toimin jos joudun identiteettivarkauden kohteeksi?',
          content: `Kun saat tiedon vahingosta, tee välittömästi rikosilmoitus poliisille ja vahinkoilmoitus
            mySafetylle. Rikosilmoitus tulee liittää mySafetylle tehtävään vahinkoilmoitukseen. Vahinkoilmoitukseen
            tulee liittää myös kopiot kirjeenvaihdosta pankin, korttiyhtiön tai muun tahon kanssa koskien
            vahinkotapahtumaa ja kopiot mahdollisista kuiteista ja laskuista liittyen vahinkotapahtumaan.
            Soita myös mySafetyn asiakaspalvelun numeroon 09 4270 4000, niin sinua autetaan
            identiteettivarkauden selvittämisessä. Selvitystyötä helpottavat kaikki tapahtumaan liittyvät
            dokumentit jotka ovat saatavissa.`
        }
      ]
    }
  }
} )

export default Vue.extend( {
  components: {
    sliders,
    questions,
    breadcrumb: () => import( '~/components/breadcrumb.vue' )
  },
  layout: 'subpage',
  head () {
    return {
      title: 'Suojaa itsesi identiteettivarkauden varalta',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: `Rahalaitoksen yhteistyökumppani mySafety tarjoaa asiakkaillemme kattavan identiteettivakuutuksen
            puoleen hintaan vuoden ajaksi.`
        }
      ]
    }
  },
  methods: {
    track ( url: string ) {
      this.$ga.query( 'send', 'event', 'outbound', 'click', url, {
        transport: 'beacon'
      } )
    }
  }
} )
