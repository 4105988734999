<script>
import _ from 'lodash'
import index from '~/components/loanApplication'

export default {
  components: {
    application: () => import( '~/components/businessApplication/application' ),
    confirmation: () => import( '~/components/businessApplication/thankYou' ),
    thankYou: () => import( '~/components/businessApplication/thankYou' )
  },
  extends: index,
  methods: {
    onSent () {
      // Send pixels
      this.$tracking.onApplicationSent( {
        luottoraja: this.$refs.application.form.luottoraja,
        maksuaika: this.$refs.application.form.maksuaika
      } )
      if ( this.$route.path.startsWith( '/cc-new' ) ) {
        this.$router.push( '/cc-new' )
      }
      else if ( this.$store.state.whitelabel ) {
        if ( this.$store.state.whitelabel.iframe ) {
          window.open( `https://${this.$domain}/oma`, '_blank' )
        }
        else {
          window.location = `https://${this.$domain}/oma`
        }
      }
      else {
        this.$router.push( 'oma' )
      }
    }
  }
}
</script>
