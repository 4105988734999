<template>
  <div>
    <main-hero
      ref="hero"
      title="Kilpailuta laina ilmaiseksi"
      :subtitle="{ desktop: 'Saat lainatarjoukset kumppaneiltamme jopa minuuteissa. Vertaa ja valitse sinulle sopivin.', touch: 'Vertaa ja valitse sinulle sopivin lainatarjous. Lainaa jopa 70 000 euroa.' }"
      side-title="Täytä lainahakemus tästä!"
    />
    <section class="hero has-background-rlgray">
      <div class="hero-body">
        <div class="container">
          <h2 class="title has-text-white is-size-4 has-text-centered">
            Laina-asiantuntija sinun puolellasi.
          </h2>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <lazy-hydrate when-visible>
          <loan-steps />
        </lazy-hydrate>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <lazy-hydrate when-visible>
          <banks />
        </lazy-hydrate>
      </div>
    </section>
    <section class="hero is-light">
      <div class="hero-body">
        <div class="container">
          <lazy-hydrate when-visible>
            <feedback />
          </lazy-hydrate>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <lazy-hydrate when-visible>
          <faq :questions="questions" />
        </lazy-hydrate>
      </div>
    </section>
    <section class="section">
      <p class="title has-text-centered">Artikkelit</p>
      <div class="container">
        <lazy-hydrate when-visible>
          <subpages />
        </lazy-hydrate>
        <p
          class="title"
          style="text-align: right; font-size: 1.1rem; margin-top: 15px;"
        >
          <nuxt-link to="/talous" class="has-text-grey-darker">
            Lisää artikkeleita
            <font-awesome-icon :icon="['fas', 'angle-right']" />
          </nuxt-link>
        </p>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <lazy-hydrate when-visible>
          <terms loan-type="consumer" />
        </lazy-hydrate>
      </div>
    </section>
    <hero-action />
  </div>
</template>

<script>

import LazyHydrate from 'vue-lazy-hydration'

export default {
  components: {
    LazyHydrate,
    banks: () => import( '~/components/layout/banks' ),
    faq: () => import( '~/components/layout/faq' ),
    feedback: () => import( '~/components/layout/feedback' ),
    heroAction: () => import( '~/components/layout/heroAction' ),
    loanSteps: () => import( '~/components/layout/loanSteps' ),
    mainHero: () => import( '~/components/layout/mainHero' ),
    subpages: () => import( '~/components/layout/subpages' ),
    terms: () => import( '~/components/layout/terms' )
  },
  data () {
    return {
      questions: [
        {
          title: 'Miksi kannattaa hakea lainaa Rahalaitoksen kautta?',
          content: `Rahalaitoksen kautta kilpailutat rahoitusyhtiöitä sekä
            pankkeja helposti ja nopeasti. Täyttämällä vain yhden hakemuksen,
            sinulla on mahdollisuus saada kerralla useampi tarjous
            vertailtavaksi. Lisäksi, lainan hakeminen ei velvoita sinua ottamaan
            lainaa ja Rahalaitoksen tarjoama vertailupalvelu on sinulle täysin
            ilmaista.`
        },
        {
          title: 'Mikä on lainan korko?',
          content: `Rahoitusyhtiöt tekevät itse lainapäätökset lainasummien sekä
            korkojen suhteen. He käyttävät apunaan mm. tulo- ja menoarviota,
            jolloin tarjoukset ovat luotettavia ja ottavat huomioon asiakkaan
            maksukyvyn. <a href="#interestRate">Esimerkkilaskurin</a> avulla
            pystyy selvittämään suuntaa antavan koron ja kk-erän.`
        },
        {
          title: 'Kuka voi hakea lainaa Rahalaitoksen kautta?',
          content: `Perusvaatimukset lainansaamiseen ovat säännölliset eläke–
            tai palkkatulot sekä puhtaat luottotiedot. Iältään lainanhakijan
            tulee olla vähintään 20-vuotias.`
        },
        {
          title: 'Onko lainan hakeminen maksutonta?',
          content: `Lainanhakeminen on täysin maksutonta, eikä se sido sinua
            mihinkään.`
        },
        {
          title: 'Miten voin alentaa nykyisten lainojen kustannuksia?',
          content: `Yhdistelylainan hakeminen kannattaa, mikäli sinulla on
            useampia pieniä lainoja. Hakemukselle täytetään kaikki nykyiset
            lainat sekä haluaako kyseisen lainan maksaa pois uudella lainalla.
            Yhdistelylainalla voi maksaa ja yhdistää pois korkeakorkoiset
            osamaksut, kulutusluotot sekä pienlainat aina 70 000 € saakka.
            Lyhentäessä yhtä lainaa tarvitsee muistaa vain yksi eräpäivä sekä
            kuukausierä. Lisäksi säästät koroissa ja lainanhoitokuluissa kun
            hoidat vain yhtä lainaa.`
        },
        {
          title: 'Mikä on yhteishakija ja onko siitä hyötyä?',
          content: `Yhteis- tai rinnakkaishakijalla tarkoitetaan henkilöä, joka
          hakee lainaa yhdessä päähakijan kanssa. Yhteishakijan kanssa lainan
          hakeminen mahdollistaa lähes aina edullisemmat lainatarjoukset sekä
          paremmat lainaehdot.`
        },
        {
          title: 'Tein virheen hakemukseen, miten pystyn korjaamaan sen?',
          content: `Paras ja nopein tapa virheen oikaisemiseen on soittaa
            asiakaspalveluumme p.
            <span class="has-text-link">09 2311 3670</span>. Näin saamme oikean
            tiedon myös lainanmyöntäjien tietoon. Voit olla yhteydessä
            asiakaspalveluumme myös chatin, Oma Rahalaitos – palvelun
            <a href="/oma/">www.rahalaitos.fi/oma</a>  tai sähköpostin
            <a href="mailto:asiakaspalvelu@rahalaitos.fi">asiakaspalvelu@rahalaitos.fi</a> kautta.`
        },
        {
          title: 'Mikä on Oma Rahalaitos -palvelu?',
          content: `Oma Rahalaitos –palvelu on portaali, jossa voi seurata
            hakemuksen käsittelyä reaaliajassa. Palvelussa näet kaikki saamasi
            lainatarjoukset, joita voit vertailla sekä saat ohjeet lainan
            nostamiseen. Omilla sivuilla
            <a href="/oma/">www.rahalaitos.fi/oma</a> pystyy myös keskustelemaan
            asiakaspalvelun kanssa hakemuksesta ja tarjouksista. Palveluun
            kirjaudutaan henkilötunnuksella ja salasanalla, joka lähetetään
            matkapuhelimeen ja sähköpostiin. Siksi on erityisen tärkeää, että
            yhteystiedot on kirjattu hakemukselle oikein.`
        },
        {
          title: 'En pääse kirjautumaan Oma Rahalaitos –palveluun?',
          content: `Oma Rahalaitos –palveluun kirjaudutaan hakemuksen
            täyttämisen jälkeen. Palvelu on avoinna koko hakemuksen
            voimassaoloajan. Mikäli kirjautuminen epäonnistuu, kehotamme olemaan
            yhteydessä asiakaspalveluumme puhelimitse
            <span class="has-text-link">09 2311 3670</span> tai chatin kautta.`
        },
        {
          title: 'Miten lainapäätös tehdään ja mitkä asiat siihen vaikuttavat?',
          content: `Rahoitusyhtiöt käsittelevät hakemuksen aina
            asiakaskohtaisesti. Lainatarjoukseen vaikuttaa positiivisesti mm.
            moitteeton maksuhistoria sekä vakituiset tulot tai eläke. Helpoin
            tapa löytää paras lainatarjous on täyttää maksuton
            <a href="#main-hero">lainahakemus</a> sivuillamme.`
        },
        {
          title: 'Voiko lainan maksaa aiottua nopeammin pois?',
          content: `Ylimääräisiä suorituksia voi tehdä milloin vain, jolloin
            takaisinmaksuaika lyhenee. Voit myös maksaa koko lainan yhdessä
            erässä pois. Korkoa maksetaan ainoastaan siltä ajalta, kun laina on
            ollut käytössä. Ennenaikaisesta takaisinmaksu järjestelystä
            kannattaa sopia aina rahoitusyhtiön kanssa, josta lainan on
            ottanut.`
        }
      ]
    }
  },
  async fetch ( { store } ) {
    await store.dispatch( 'subpage/setPages', [
      {
        title: 'Kevätaurinko kurkkii',
        subtitle: 'Säästää kesähankinnoissa',
        link: '/talous/katso-vinkit-kesahankintoihin',
        image: '/cms/uploads/large_richard_ciraulo_o_F3_Rbiki6_HQ_unsplash_1b349b1b35.jpg'
      },
      {
        title: 'Yhdistä lainasi',
        subtitle: 'Selkiytä talouttasi ja säästä kuluissa',
        link: '/yhdista-lainat'
      },
      {
        title: 'Kilpailuta pohjoismaiset pankit',
        subtitle: 'Vertaile ja katso mistä saat edullisimman lainan',
        link: '/talous/suomalainen-ruotsalainen-vai-norjalainen-pankki',
        image: '/cms/uploads/large_photo_1538332576228_eb5b4c4de6f5_3bdf11ac3a.jpg'
      }
    ] )
  },
  head () {
    return {
      title: 'Lainojen kilpailutus - Kilpailuta lainat ilmaiseksi',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content:
            `Rahalaitos on suomalainen lainanvälityspalvelu, joka kilpailuttaa
            yli 20 pankkia puolestasi täysin ilmaiseksi. Kokeile ja hae lainaa
            heti!`
        }
      ],
      link: [
        { rel: 'canonical', href: 'https://www.rahalaitos.fi/' }
      ]
    }
  },
  methods: {
    scrollToHero () {
      this.$refs.hero.$el.scrollIntoView( {
        behavior: 'smooth',
        block: 'start'
      } )
    }
  }
}
</script>

<style>
</style>
