export default {
  Nordax: 'nordaxbank',
  Avida: 'avida',
  Svea: 'svea',
  Halino: 'halino',
  Lainaamo: 'lainaamo',
  BigBank: 'bigbank',
  Collector: 'collectorbank',
  Alisa: 'alisa',
  TFBank: 'credento',
  Credigo: 'credigo',
  MyLender: 'mylender',
  Ferratum: {
    raw: 'instabank',
    path: require( '~/static/partners/ferratum.svg' )
  },
  Brocc: 'brocc',
  'Lea Bank': 'leabank',
  'Resurs Bank': 'resursbank',
  Morrowbank: {
    raw: 'komplettbank',
    path: require( '~/static/partners/morrow.svg' )
  },
  Instabank: {
    raw: 'instabank',
    path: require( '~/static/partners/instabank.svg' )
  },
  Bondora: {
    raw: 'bondora',
    path: require( '~/static/partners/bondora.svg' )
  },
  Norwegian: 'norwegian'
}
