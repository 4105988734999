<template>
  <nav class="navbar is-spaced is-transparent has-text-weight-bold">
    <div class="container">
      <div class="navbar-brand">
        <nuxt-link class="navbar-item" to="/">
          <logo-svg />
        </nuxt-link>
        <div :class="{ 'is-active': toggleNavbar }" class="navbar-burger burger" @click="toggleNavbar = !toggleNavbar">
          <span />
          <span />
          <span />
        </div>
      </div>
      <div :class="{ 'is-active': toggleNavbar }" class="navbar-menu">
        <div class="navbar-end">
          <template v-if="$store.state.customer.hasBothTypes">
            <a
              :class="{ 'is-active': $store.state.customer.loanType === 'consumer' }"
              class="navbar-item has-seperator"
              @click="setType('consumer')"
            >
              Kuluttajalainat
            </a>
            <a
              :class="{ 'is-active': $store.state.customer.loanType === 'business' }"
              class="navbar-item has-seperator"
              @click="setType('business')"
            >
              Yrityslainat
            </a>
          </template>
          <span class="navbar-item has-seperator">
            <span class="navbar-icon">
              <font-awesome-icon :icon="['fas', 'phone']" size="lg" />
            </span>
            09 2311 3670
          </span>
          <div v-if="$store.getters['auth/isLoggedIn']" class="navbar-item has-dropdown has-seperator is-hoverable">
            <a class="navbar-link">
              <span class="navbar-icon">
                <font-awesome-icon :icon="['far', 'user-circle']" size="lg" fixed-width />
              </span>
              <span>
                <p>{{ $store.getters['customer/fullName'] }}</p>
                <p v-if="$store.state.customer.loanType === 'business'">{{ $store.state.customer.applicant.yritysnimi }}</p>
              </span>
            </a>
            <div class="navbar-dropdown">
              <a class="navbar-item" @click="settingsModal = !settingsModal">
                <span class="navbar-icon">
                  <font-awesome-icon :icon="['far', 'sliders-h']" size="lg" fixed-width />
                </span>
                Asetukset
              </a>
              <a class="navbar-item" @click="logout">
                <span class="navbar-icon">
                  <font-awesome-icon :icon="['fas', 'sign-out']" size="lg" fixed-width />
                </span>
                Kirjaudu ulos
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <settings-modal
      v-if="$store.getters['auth/isLoggedIn']"
      class="has-text-weight-normal"
      :active="settingsModal"
      @close="settingsModal = !settingsModal"
    />
  </nav>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'LoginNavbar',
  components: {
    logoSvg: () => import( '~/assets/img/logo.svg?inline' ),
    settingsModal: () => import( '~/components/login/navbar/settingsModal' )
  },
  data () {
    return {
      toggleNavbar: false,
      settingsModal: false
    }
  },
  methods: {
    ...mapActions( 'auth', [
      'logout'
    ] ),
    /**
     * Set's loan type and update's offers
     */
    async setType ( type ) {
      // Refresh decision
      const decisionsEl = document.querySelector( '.decisions.box' )
      if ( decisionsEl ) {
        await decisionsEl.__vue__.updateOffers( true, type )
      }
    }
  }
}
</script>
