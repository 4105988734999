<template>
  <div>
    <main-hero
      title="Paras laina voittakoon!"
      subtitle="Yhdellä hakemuksella useita lainatarjouksia."
      side-title="Kilpailuta lainasi!"
      :hero-background="require('~/assets/img/areena.png')"
      :side-boxes="sideBoxes"
      survey-title="Asiakasarviot"
      :breadcrumb="{'MM-kisat': ''}"
    >
      <img id="mm-stamp" :src="require( '~/assets/img/mm-jaakiekko-yhteistyo.png' )" alt="MM Yhteistyöleima">
      <loan-application />
    </main-hero>
    <section class="hero has-background-rlgray">
      <div class="hero-body">
        <div class="container">
          <h2 class="title has-text-white is-size-4 has-text-centered">
            Kannusta Suomi voittoon kotikisoissa!
          </h2>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="content">
          <h2>
            Toukokuussa taloutesi ykköskoutsi johdattaa sekä Suomen että lainankilpailuttajat voittoputkeen.
          </h2>
          <p>
            Jääkiekon maailmanmestaruuskilpailut järjestetään 86:nen kerran tänä keväänä. Turnauksessa mittaa ottavat
            toisistaan 16 maata, jotka ovat jaettu kahteen lohkoon. 12.5 – 28.5.2023 käytävä turnaus järjestetään sekä
            Tampereella että Riikassa. Kaikki A-lohkon pelit, johon kuuluu myös Suomi, sekä välierät ja loppuottelu
            pelataan Tampereella, Nokia Areenalla. B-lohkon pelit pelataan Riika-areenalla Riikassa ja puolivälierät
            taas jaetusti kummassakin isäntäkaupungissa.
          </p>
          <p>
            Kisat alkavat perjantaina 12.5. neljällä ottelulla. Tampereella päästään heti nauttimaan jännittävistä
            kamppailuista, sillä avausottelussa klo 16.20 Suomi kohtaa Yhdysvallat ja iltapelissä klo 20.20 Ruotsi
            haastaa Saksan. Samaan aikaan Latviassa pelataan klo 16.20 Slovakia-Tsekki sekä klo 20.20 Latvia-Kanada
            ottelut.
          </p>
          <p>
            Tuttuun tapaan Rahalaitos on vahvasti mukana myös tämän kevään MM-kisoissa. Toimimme kisojen lähetyksistä
            vastaavan MTV:n pääyhteistyökumppanina. MTV 3-kanava näyttää kisojen aikana suorana Suomen alkusarjan pelit
            sekä kaikki jatkosarjan ottelut. Nämä ja kaikki muut turnauksen ottelut ovat nähtävillä C More Sport
            kanavilla sekä C Moren suoratoistopalvelussa.
          </p>
          <p>
            Rahalaitoksen näkyvyys kilpailuissa on merkittävä. Tulette bongaamaan meitä lähetyksissä otteluiden aikana
            läpi turnauksen ja tarjoamme myös katsojille kisastudiossa käytävän pelialueanalyysin. Taloutesi ykköskoutsi
            tulee siis olemaan varsin tuttu näky kotisohvilla.
          </p>
          <p>
            Mikäli haluat pysyä perillä Suomen matkasta läpi turnauksen, ota seurantaan Rahalaitoksen
            Facebook-sivut: <a href="https://www.facebook.com/rahalaitos">https://www.facebook.com/rahalaitos</a>.
            Julkaisemme sivuillamme mielenkiintoista infoa muun muassa kisojen aikatauluista, Suomen kokoonpanoista
            sekä tietysti järjestämistämme kilpailuista. FB-Sivuiltamme on mahdollista voittaa lukuisia kiekkoaiheisia
            palkintoja, joten sivuston tarkka seuraaminen todella kannattaa.
          </p>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <lazy-hydrate when-visible>
          <subpages />
        </lazy-hydrate>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <lazy-hydrate when-visible>
          <banks />
        </lazy-hydrate>
      </div>
    </section>
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'

export default {
  name: 'Leijonat',
  components: {
    LazyHydrate,
    banks: () => import( '~/components/layout/banks' ),
    mainHero: () => import( '~/components/layout/mainHero' ),
    subpages: () => import( '~/components/layout/subpages' ),
    loanApplication: () => import( '~/components/loanApplication' )
  },
  data () {
    return {
      sideBoxes: [
        {
          icon: () => import( '~/assets/img/icons/banks.svg?inline' ),
          title: 'Lainatarjous jopa',
          subtitle: '25 pankilta'
        },
        {
          icon: () => import( '~/assets/img/icons/time.svg?inline' ),
          title: 'Helposti ja',
          subtitle: 'nopeasti'
        },
        {
          icon: () => import( '~/assets/img/icons/money.svg?inline' ),
          title: 'Lainaa jopa',
          subtitle: '70 000 €'
        }
      ]
    }
  },
  async fetch ( { store } ) {
    await store.dispatch( 'subpage/setPages', [
      {
        title: 'Lainaa ilman takaajia',
        subtitle: 'Haaveista totta nopeasti ja helposti',
        link: '/lainaa-ilman-takaajia'
      },
      {
        title: 'Lainaa 30 000 euroa',
        subtitle: 'Suuriin ja pieniin unelmiin',
        link: '/lainaa-30000-euroa'
      },
      {
        title: 'Yhdistä lainasi',
        subtitle: 'Selkiytä talouttasi ja säästä kuluissa',
        link: '/yhdista-lainat'
      }
    ] )
  },
  head () {
    return {
      title: 'Jääkiekon MM-kisat 2023 Tampere - Riika | Rahalaitos.fi',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content:
            `Rahalaitos on Suomessa järjestettävien jääkiekon MM-kisojen ajan MTV:n
            virallinen pääyhteistyökumppani. Lue lisää toiminnastamme tältä sivulta.`
        }
      ],
      link: [
        { rel: 'canonical', href: 'https://www.rahalaitos.fi/mmkisat' }
      ]
    }
  },
  mounted () {
    if ( !this.$cookies.get( 'RL_arrive' ) ) {
      this.$cookies.set( 'RL_arrive', 'RL_leijonat' )
    }
  }
}
</script>
<style lang="scss">
  .page-mmkisat {
    .leijona-logo {
      max-height: 3rem;
    }
    #main-hero {
      background-position: bottom;

      #main-hero-container > .is-7-desktop {
        position: relative;
      }
      #mm-stamp {
        @include desktop() {
          top: -20px;
          right: -20px;
          width: 120px;
        }
        position: absolute;
        z-index: 1;
        width: 100px;
        top: -40px;
        right: 0;
        transform: rotate(10deg);
      }
      .breadcrumb {
        display: none;
      }
      .loan-change {
        left: 10px;
      }
    }
  }
</style>
