<template>
  <div>
    <main-hero
      title="Kilpailuta venelaina maksutta"
      subtitle="Yhdellä hakemuksella useita lainatarjouksia."
      side-title="Kilpailuta venelainasi!"
      :side-boxes="sideBoxes"
      survey-title="Asiakasarviot"
      :breadcrumb="{'Lainaa': '/lainaa', 'Venelaina': ''}"
    />
    <section class="hero has-background-rlgray">
      <div class="hero-body">
        <div class="container">
          <h2 class="title has-text-white is-size-4 has-text-centered">
            Venelaina kannattaa kilpailuttaa.
          </h2>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <lazy-hydrate when-visible>
          <loan-steps />
        </lazy-hydrate>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="content">
          <h2>
            Venelaina - Kilpailuttamalla säästät venekustannuksissa
          </h2>
          <p>
            <i>
              Mikä olisikaan parempi tapa viettää kesäpäivää kuin vesillä liikkuminen Suomen tuhansilla järvillä. Upeat
              järvimaisemat ja saaristot tarjoavat veneilyyn mitä mainioimmat puitteet. Mukavin tapa viettää vapaa-aikaa
              vesillä on tietenkin oma vene. Venettä voi maisemien nautiskelun lisäksi käyttää liikkumiseen esimerkiksi
              mökille tai hyvien kala-apajien äärelle.
            </i>
          </p>
          <p>
            Oli haaveissasi sitten uusi tai käytetty vene, purjeilla tai moottorilla, voi veneen osto olla silti
            yllättävän iso hankinta. Vene on harvoin heräteostos, vaan sen hankintaa on mietitty jo jokunen tovi.
            Joillain taas aikaisemmin puraissut venekärpänen on voinut johtaa nyt suuremman paatin hankintaan. Venettä
            varten on voitu säästää omaa pääomaa jonkin aikaa, mutta usein venettä varteen otetaan myös lisärahoitusta.
            Rahalaitos myöntää edullisia vakuudettomia venelainoja aina 70 000 euroon asti, joten veneen hankinta ei
            enää nykypäivänä jää rahoituksesta kiinni.
          </p>
          <h3>
            Miksi ottaa venerahoitus?
          </h3>
          <p>
            Veneet ovat yleensä sen verran hintavia hankintoja, että säästöjen ohessa on turvauduttava ulkopuoliseen
            rahoitukseen. Venelainojen korot ja kulut eroavat paljonkin pankista ja lainan suuruudesta riippuen.
            Tällöin on tärkeää vertailla venelainoja eri pankkien välillä, sillä pienetkin erot venelainan marginaalissa
            voivat kasvaa suuriksi pitkällä laina-ajalla.
          </p>
          <p>
            Venelainaa varten on perinteisesti säästetty pitkään, tai vastaavasti pankit ovat vaatineet suurehkoja
            vakuuksia lainansa turvaksi. Kaikilla sellaisia säästöjä tai vakuuksia ei ole, joten tällöin kannattanee
            miettiä nopean ja kätevän vakuudettoman lainan ottamista. Useat veneenmyyjät vaativat koko kauppasummaa
            kerralla, joten kun eteesi osuu täydellinen vene, on nopea ja joustava venelaina todella arvokas tässä
            tilanteessa.
          </p>
          <p>
            Vakuudeton venelaina on mahdollista hakea nopeasti ja Rahalaitos.fi tarjoaa tähän tilanteeseen yli 20
            luotonantajaa, jotka voit kilpailuttaa yhdellä hakemuksella. Pääset vertailemaan kaikki lainatarjoukset
            samasta paikasta ja sopivimman lainan ottamalla varmistat, että unelmiesi vene ei pääse livahtamaan
            hyppysistäsi. Koska suomalaiset valitsevat käytettyjen veneiden markkinoilta yleisimmin 20 000 - 30 000
            euron hintaisen veneen, edullisimman lainan valitseminen voi tarkoittaa jopa satojen eurojen säästöä
            kuukausitasolla.
          </p>
          <h3>
            Kuinka paljon venelainaa tarvitsen?
          </h3>
          <p>
            On hyvä pitää mielessä, että veneily ei ole mitään halpaa hupia vaan myös veneen ylläpitoon kuluu rahaa.
            Edullinen venelaina on tarkoitettu rahoittamaan kaikki veneeseesi liittyvät kustannukset. Hyviä
            käyttökohteita ovatkin itse veneen hankinnan lisäksi veneen korjaus sekä uusien veneen osien ja lisä- ja
            turvavarusteiden ostaminen. Voit siis helposti samalla venelainalla korjata viallisen moottorin tai vaikka
            päivittää uudet istuintyynyt ja taittotuolit. Venerahoitus kattaa siis oston lisäksi myös veneen korjailuun
            ja muuhun päivittämiseen liittyvät kustannukset.
          </p>
          <p>
            Kun miettii lainaa venettä varten, on hyvä muista myös ylläpitokustannukset. Venepaikka, veneen laskeminen
            ja nostaminen, talvisäilytys, huollot, katsastus, mahdollinen vakuutus sekä tietysti polttoaine. Muista siis
            lainaa kilpailuttaessa ottaa huomioon edellä mainitut menoerät, kun mietit lainasumman määrää. Edullinen
            venelaina auttaa selviämään myös ylläpitokustannuksista, jolloin kesän venereissuja ei tarvitse rajoittaa
            ainakaan taloudellisen tilanteen vuoksi.
          </p>
          <h3>
            Kuinka haen venelainaa?
          </h3>
          <p>
            Vakuudettoman venelainan kilpailuttaminen ja hakeminen on helppoa sekä nopeaa. Tee näin:
          </p>
          <p>
            <ol>
              <li><nuxt-link to="/lainaa">Täytä lainahakemus</nuxt-link> Rahalaitos.fi sivustolla.</li>
              <li>
                Vertaile saamiasi tarjouksia Oma Rahalaitos -palvelusta.
              </li>
              <li>
                Valitse sinulle sopivin laina.
              </li>
              <li>
                Kättele kaupat unelmaveneestäsi ja lähde järvelle nauttimaan suomen suvesta.
              </li>
            </ol>
          </p>
          <h3>
            Mitä minun tulee tietää venelainan hakemisesta?
          </h3>
          <p>
            Venelainan kilpailutus kauttamme on täysin maksutonta. Hakemuksessa saat itse valita sinulle sopivan
            lainasumman sekä takaisinmaksuajan. Yhdellä hakemuksella kilpailutamme kymmeniä lainatarjouksia ja saat
            itse valita niistä sopivimman. Lainantarjoajat arvioivat maksukykysi tulojesi ja maksuhistoriasi mukaan ja
            asettavat lainakorkonsa sen pohjalta. Hakemuksen teko ja lainojen vertailu eivät sido sinua ottamaan lainaa.
            Mikäli hyväksyt lainatarjouksen, saat rahat tilillesi vielä jopa saman päivän aikana.
          </p>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <lazy-hydrate when-visible>
          <faq />
        </lazy-hydrate>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <lazy-hydrate when-visible>
          <subpages />
        </lazy-hydrate>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <lazy-hydrate when-visible>
          <banks />
        </lazy-hydrate>
      </div>
    </section>
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'

export default {
  name: 'Venelaina',
  components: {
    LazyHydrate,
    banks: () => import( '~/components/layout/banks' ),
    faq: () => import( '~/components/layout/pages/venelainaFaq' ),
    loanSteps: () => import( '~/components/layout/loanSteps' ),
    mainHero: () => import( '~/components/layout/mainHero' ),
    subpages: () => import( '~/components/layout/subpages' )
  },
  data () {
    return {
      sideBoxes: [
        {
          icon: () => import( '~/assets/img/icons/banks.svg?inline' ),
          title: 'Lainatarjous jopa',
          subtitle: '25 pankilta'
        },
        {
          icon: () => import( '~/assets/img/icons/time.svg?inline' ),
          title: 'Helposti ja',
          subtitle: 'nopeasti'
        },
        {
          icon: () => import( '~/assets/img/icons/money.svg?inline' ),
          title: 'Lainaa jopa',
          subtitle: '70 000 €'
        }
      ]
    }
  },
  async fetch ( { store } ) {
    await store.dispatch( 'subpage/setPages', [
      {
        title: 'Lainaa ilman takaajia',
        subtitle: 'Haaveista totta nopeasti ja helposti',
        link: '/lainaa-ilman-takaajia'
      },
      {
        title: 'Lainaa 30 000 euroa',
        subtitle: 'Suuriin ja pieniin unelmiin',
        link: '/lainaa-30000-euroa'
      },
      {
        title: 'Yhdistä lainasi',
        subtitle: 'Selkiytä talouttasi ja säästä kuluissa',
        link: '/yhdista-lainat'
      }
    ] )
  },
  head () {
    return {
      title: 'Venelaina - Kilpailuta venelaina | Rahalaitos',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: `Rahalaitoksen kautta voit saada jopa 70 000 € venelainaa ilman vakuuksia.
            Täytä hakemus ilmaiseksi jo tänään!`
        }
      ],
      link: [
        { rel: 'canonical', href: 'https://www.rahalaitos.fi/venelaina' }
      ]
    }
  }
}
</script>
