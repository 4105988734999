<template>
  <div class="content">
    <breadcrumb
      :path="{'Lainaa': '/lainaa', 'Lainakumppanit': ''}"
    />
    <h1 class="has-text-centered">Rahalaitoksen yhteistyökumppanit kuluttajalainoissa</h1>
    <p class="has-text-centered">
      Me Rahalaitoksella etsimme sinulle parhaat lainatarjoukset seuraavilta yhteistyökumppaneinamme
      toimivilta pankeilta ja lainantarjoajilta. Etsimme jatkuvasti uusia kumppaneita, joiden avulla
      voimme taata sen, että saat aina kilpailukykyiset lainatarjoukset vertailtavaksi palvelussamme.
    </p>
    <!-- Client only because vue hydration fails for some reason -->
    <client-only>
      <bank-links v-if="partners.length" :visible="get()" />
    </client-only>
  </div>
</template>

<script>

import Yhteistyokumppanit from '~/pages/yhteistyokumppanit'

export default {
  name: 'Lainakumppanit',
  extends: Yhteistyokumppanit,
  layout: 'subpage',
  head () {
    return {
      title: 'Yhteistyökumppanit | Rahalaitos',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: `Me etsimme sinulle parhaat lainatarjoukset
          yhteistyökumppaneinamme toimivien pankkien ja
          lainanmyöntäjien joukosta.`
        }
      ]
    }
  }
}
</script>
