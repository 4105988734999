






















import { defineComponent } from '@nuxtjs/composition-api'
import partnerImages from '~/utils/partners'
import type { Partner, PartnerLink } from '~/types/cms/partners'

export default defineComponent( {
  name: 'Yhteistyokumppanit',
  components: {
    bankLinks: () => import( '~/components/layout/bankLinks.vue' ),
    breadcrumb: () => import( '~/components/breadcrumb.vue' )
  },
  layout: 'subpage',
  data () {
    return {
      partners: [] as Partner[]
    }
  },
  async fetch () {
    this.partners = await this.$axios.$get( '/cms/partners' )
  },
  head () {
    return {
      title: 'Yhteistyökumppanit | Rahalaitos',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: `Me etsimme sinulle parhaat lainatarjoukset
          yhteistyökumppaneinamme toimivien pankkien ja
          lainanmyöntäjien joukosta.`
        }
      ]
    }
  },
  methods: {
    get ( type: string = 'consumer' ): PartnerLink[] {
      const result: PartnerLink[] = []
      this.partners.forEach( ( partner: Partner ) => {
        if ( partner.type === type ) {
          const img = this.getImg( partner )
          if ( img ) {
            result.push( {
              title: partner.title,
              slug: partner.slug,
              img
            } )
          }
        }
      } )
      return result
    },
    getImg ( partner: Partner ): string|object|undefined {
      // Get image by title
      if ( ( partnerImages as {[key: string ]: string|object} )[partner.title] ) {
        return ( partnerImages as {[key: string ]: string|object} )[partner.title]
      }
      // Get image by slug
      else if ( ( partnerImages as {[key: string ]: string|object} )[this.capitalizeFirstLetter( partner.slug )] ) {
        return ( partnerImages as {[key: string ]: string|object} )[this.capitalizeFirstLetter( partner.slug )]
      }
      // Use image from cms
      else if ( partner.image && partner.image.url ) {
        return `/cms/${partner.image.url}`
      }
      return undefined
    },
    capitalizeFirstLetter ( string: string ): string {
      return string.charAt( 0 ).toUpperCase() + string.slice( 1 )
    }
  }
} )
