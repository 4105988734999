<template>
  <div class="accordion">
    <div class="columns is-multiline">
      <div v-for="n in 2" :key="n" class="column" :class="columnClass">
        <div v-for="(item, key) in filteredItems(n)" :key="key = items.indexOf(item)" class="accordion__box">
          <div :class="{ 'opened': opened === key }" class="accordion__title" @click="opened === key ? opened = null : opened = key">
            <font-awesome-icon :icon="['far', opened === key ? 'minus-circle' : 'plus-circle']" size="lg" />
            <span>{{ item.title }}</span>
          </div>
          <vertical-transition :active="opened === key">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div class="accordion__content content" v-html="item.content" />
          </vertical-transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Accordion',
  components: {
    verticalTransition: () => import( '~/components/transitions/vertical' )
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    columns: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      opened: null
    }
  },
  computed: {
    columnClass () {
      return `is-${12 / this.columns}`
    }
  },
  methods: {
    filteredItems ( column ) {
      return this.items.filter( item => this.items.indexOf( item ) % this.columns === column - 1 )
    }
  }
}
</script>

<style lang="scss">
.accordion {
  @include mobile {
    > .columns {
      .column:first-child {
        padding-bottom: 0;
      }
      .column:last-child {
        padding-top: 0;
      }
    }
  }

  &__box {
    border-radius: 15px;
  }

  &__title {
    cursor: pointer;
    display: flex;
    font-weight: 700;
    padding: 0.75rem 1em 0.75rem 0;
    svg {
      color: $rl-green;
    }
    span {
      margin-left: 0.25rem;
    }
  }

  & .accordion__content {
    margin-top: -.2rem;
    padding: 0 1rem 1rem 1.70em;
    &.slideDown-leave,
    &.slideDown-enter-to {
      max-height: 400px;
      @include desktop {
        max-height: 160px;
      }
    }
    &.content {
      padding: .5rem;
    }
  }
}
</style>
