<template>
  <div>
    <loan-application ref="appCont" />
  </div>
</template>

<script>

import * as Resize from 'iframe-resizer'
import Vue from 'vue'

Vue.use( Resize )

export default Vue.extend( {
  components: {
    loanApplication: () => import( '~/components/loanApplication' )
  },
  layout: 'iframe',
  head () {
    return {
      meta: [
        { hid: 'robots', name: 'robots', content: 'noindex' }
      ]
    }
  },
  mounted () {
    window.iFrameResizer = {
      onMessage: ( message ) => {
        // Clear application data
        if ( Object.prototype.hasOwnProperty.call( message, 'clear' ) && message.clear ) {
          if ( this.$refs.appCont.$refs.application ) {
            this.$refs.appCont.$refs.application.clearForm()
          }
          else {
            this.$store.dispatch( 'loanApplication/clear' )
          }
          // Clear incomplete application
          this.$axios.$post( '/v1/?action=clearContact' )
        }
        // Fill applications if message contains application info
        if ( Object.prototype.hasOwnProperty.call( message, 'application' ) ) {
          const appData = message.application
          // Application loaded
          if ( this.$refs.appCont.$refs.application ) {
            this.$refs.appCont.$refs.application.mergeFormData( appData )
            // Start incomplete instance when application is filled
            if ( appData.firstStep && message.incID && message.secret ) {
              this.$axios.$post( '/v1/',
                {
                  incID: message.incID,
                  secret: message.secret
                },
                {
                  params: {
                    action: 'activateIncomplete'
                  }
                }
              ).then( () => {
                this.$refs.appCont.$refs.application.startIncomplete()
              } )
            }
          }
          // Application not loaded
          else {
            this.$store.dispatch( 'loanApplication/merge', appData )
          }
        }
      }
    }
  }
} )
</script>
