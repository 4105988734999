<template>
  <div class="breadcrumb">
    <ol>
      <li>
        <nuxt-link to="/"> Rahalaitos </nuxt-link>
      </li>
      <li v-for="(value, key) in path" :key="key">
        <nuxt-link :to="value ? value : ''"> {{ key }} </nuxt-link>
      </li>
    </ol>
    <script type="application/ld+json" v-html="ldJson" />
  </div>
</template>

<script>
export default {
  name: 'Breadcrumb',
  components: {
  },
  props: {
    path: {
      type: Object,
      required: true
    }
  },
  computed: {
    ldJson () {
      const json = {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: []
      }
      let order = 1
      for ( const [key, value] of Object.entries( this.path ) ) {
        const page = {}
        page['@type'] = 'ListItem'
        page.position = order++
        page.name = key
        if ( value ) {
          page.item = 'https://www2.rahalaitos.fi' + value
        }
        json.itemListElement.push( page )
      }
      return JSON.stringify( json )
    }
  }
}
</script>

<style lang="scss">
// Pages with full application
#main-hero .breadcrumb {
  margin: 0 0 0 10px;
  position: absolute;
  top: -12px;
  a {
    color: white;
  }
  @include mobile {
    display: none;
  }
  @include desktop {
    @include until (1200px) {
      display: none;
    }
  }
}

// Subpage
.subpage .breadcrumb {
  @include tablet {
    margin-top: -1.7rem;
  }
  margin-bottom: 1rem;
  a {
    color: $rl-gray;
  }
}

// Global styles
.breadcrumb {
  ol {
    // Important, because pages css loaded before app.
    margin: 0 !important;

    li {
      &:last-child a {
        font-weight: 400;
      }
      a {
        font-weight: 500;
      }
    }

    li+li {
      margin-top: 0;
    }
  }
}
</style>
