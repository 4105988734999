<template>
  <div class="subpage_sliders" :class="[styleClasses]">
    <div class="columns">
      <div class="column">
        <loan-slider
          key="luottoraja"
          v-model.number="form.luottoraja"
          name="luottoraja"
          :max="getMaxAmount"
          :min="getMinAmount"
          :slider-style="sliderStyle"
          :start="luottoraja"
          :step="getMinAmount"
        />
      </div>
      <div class="column">
        <loan-slider
          key="maksuaika"
          v-model.number="form.maksuaika"
          name="maksuaika"
          title="Laina-aika"
          :slider-style="sliderStyle"
          :format="(value) => businessLoan ? Math.round(value) + ' kk' : parseInt(value, 10) + ' vuotta'"
          :max="getLoanTime"
          :min="1"
          :start="maksuaika"
          :step="1"
        />
      </div>
    </div>
    <div v-if="!businessLoan" class="subpage_sliders_interest">
      <p class="is-size-5 has-text-weight-bold has-text-centered">
        <sup>*</sup>Lainan kuukausierän arvio: <span class="is-size-4">{{ data.monthly }} €/kk</span>
      </p>
    </div>
    <slot />
    <div v-if="!disableButton" class="columns">
      <div class="column has-text-centered">
        <a class="button is-rounded is-primary" @click="goToApplication">Hae lainatarjoukset</a>
      </div>
    </div>
    <interest-rate
      v-if="!businessLoan"
      ref="interestRate"
      class="is-size-7 is-italic has-text-weight-light"
      :loan-amount="form.luottoraja"
      :loan-period="form.maksuaika"
      @monthlyChanged="data.monthly = $event"
    />
  </div>
</template>

<script>

export default {
  name: 'SubPageSldiers',
  components: {
    loanSlider: () => import( '~/components/form/loanSlider' ),
    interestRate: () => import( '~/components/form/interestRate' )
  },
  props: {
    luottoraja: {
      type: Number,
      default: 15000
    },
    maksuaika: {
      type: Number,
      default: 8
    },
    additional: {
      type: Object,
      default: () => ( {} )
    },
    sliderStyle: {
      type: Number,
      default: 2
    },
    styleClasses: {
      type: Array,
      default: () => ['subpage_sliders__style-1']
    },
    disableButton: {
      type: Boolean,
      default: false
    },
    goToUrl: {
      type: String,
      default: '/'
    },
    businessLoan: {
      type: Boolean,
      default: false
    },
    vaurausLoan: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      data: {
        monthly: 0
      },
      form: {
        luottoraja: this.luottoraja,
        maksuaika: this.maksuaika
      }
    }
  },
  computed: {
    getMaxAmount () {
      let ret
      if ( this.vaurausLoan ) {
        ret = 8000000
      }
      else if ( this.businessLoan ) {
        ret = 250000
      }
      return ret
    },
    getMinAmount () {
      let ret
      if ( this.vaurausLoan ) {
        ret = 251000
      }
      else if ( this.businessLoan ) {
        ret = 1000
      }
      return ret
    },
    getLoanTime () {
      let ret = 15
      if ( this.vaurausLoan ) {
        ret = 36
      }
      else if ( this.businessLoan ) {
        ret = 24
      }
      return ret
    }
  },
  methods: {
    /**
     * Goes to application
     *
     * @param  additional {object} Used by external import
     */
    goToApplication ( additional = {} ) {
      this.$store.dispatch( 'loanApplication/merge', { ...this.form, ...this.additional, ...additional } )
      this.$router.push( this.goToUrl )
    }
  }
}
</script>

<style lang="scss">
  .subpage_sliders {
    padding: 1.5rem;
    border-radius: 5px;
    @include mobile() {
      padding: 1.5rem 0.75rem;
    }

    &_interest {
      padding: 1rem 1.5rem;
      margin-bottom: 1rem;
      border-radius: 5px;
    }

    &__style-1 {
      background-color: $light;
      .subpage_sliders_interest {
        color: #6bd100;
        padding: 0;
      }
    }

    &__style-2 {
      background-color: $light;
      .subpage_sliders_interest {
        background-color: $rl-green;
        color: #fff;
      }
    }
  }
</style>
