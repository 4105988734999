<template>
  <div class="columns is-mobile is-centered">
    <div class="column is-9-mobile-md is-8-mobile-lg is-6-tablet is-5-desktop is-4-widescreen">
      <div class="content app">
        <div>
          <p class="title is-5 has-text-centered">
            Tervetuloa
          </p>
          <p class="subtitle is-6 has-text-centered">
            Kirjaudu syöttämällä tunnuksesi.
          </p>
          <form @submit.prevent="submitLogin">
            <div class="field">
              <div class="control">
                <form-input
                  key="form.username"
                  v-model="form.username"
                  v-validate="'required'"
                  :error="errors.first('username')"
                  name="username"
                  type="text"
                  placeholder="Käyttäjänimi"
                  input-class="is-large has-text-centered"
                />
              </div>
            </div>
            <div class="field">
              <div class="control">
                <form-input
                  key="form.password"
                  v-model="form.password"
                  v-validate="'required'"
                  :error="errors.first('password')"
                  name="password"
                  input-class="is-large has-text-centered"
                  type="password"
                  placeholder="Salasana"
                />
              </div>
            </div>
            <button type="submit" class="button is-rounded is-primary is-fullwidth">
              Jatka
            </button>
          </form>
          <article v-if="data.applicationError" class="message is-danger" style="margin-top: 1em;">
            <div class="message-body">
              {{ data.applicationError }}
            </div>
          </article>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  $_veeValidate: {
    validator: 'new'
  },
  layout: 'cc',
  components: {
    formInput: () => import( '~/components/form/input' )
  },

  head () {
    return {
      title: 'Rahalaitos | Call Center'
    }
  },
  data () {
    return {
      form: {
        username: '',
        password: ''
      },
      data: {
        applicationError: null
      }
    }
  },
  async asyncData ( { app, store, redirect } ) {
    // Validate if already sign in
    const result = await app.$axios.$get( '/v1/cc/login', {
      validateStatus: function ( status ) {
        return status === 200 || status === 208
      }
    } )
    if ( result.status === 208 ) {
      redirect( '/cc-new' )
    }
  },
  methods: {
    async submitLogin () {
      const validateResult = await this.$validator.validate()
      if ( !validateResult ) {
        this.data.applicationError = 'Tarkista että olet syöttänyt käyttäjätunnuksen ja salasanan.'
        return false
      }
      const result = await this.$axios.$post( '/v1/cc/login', this.form, {
        validateStatus: function ( status ) {
          return status === 200 || status === 401
        }
      } )
      // Invalid credentials
      if ( result.status === 401 ) {
        this.data.applicationError = 'Kirjautuminen epäonnistui! Mikäli ongelma toistuu edelleen, niin ota yhteyttä asiakaspalveluumme.'
      }
      // Login successful
      else if ( result.status === 200 ) {
        const cookiePrefix = this.form.username.split( '.' )[0]
        this.$cookies.set( 'RL_arrive', `RL_${cookiePrefix}` )
        this.$router.push( '/cc-new' )
      }
    }
  }
}
</script>
